import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  Button,
  Box,
  Tooltip,
  Typography,
  Divider,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tabs,
  Tab,
  Alert,
  AlertTitle,
  CircularProgress,
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import DraggableItem from './DraggableItem';
import LoadProject from './LoadProject';
import SaveProject from './SaveProject';
import { modulesConfig } from '../config/modules';
import { supabase } from '../supabaseClient';
import { toast } from 'react-toastify';
import ProfileMenu from './ProfileMenu';
import useStore from '../zustandStore';

import './Toolbar.css';

const Toolbar = ({ user, showDropdown, toggleDropdown, onNewProject, onProjectLoaded }) => {
  const [showYourWork, setShowYourWork] = useState(false);
  const [currentProjectId, setCurrentProjectId] = useState(null);
  const [currentProjectName, setCurrentProjectName] = useState('Untitled');
  const [projects, setProjects] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openEditAccount, setOpenEditAccount] = useState(false);
  const [openUsage, setOpenUsage] = useState(false);
  const [credits, setCredits] = useState(0);
  const [loading, setLoading] = useState(false);

  // Tab index for the dropdown menu (Modules, Scenarios, Actions, User Input)
  const [dropdownTabIndex, setDropdownTabIndex] = useState(0);

  const dropdownRef = useRef(null);
  const yourWorkRef = useRef(null);

  // Zustand store references
  const nodes = useStore((state) => state.nodes);
  const edges = useStore((state) => state.edges);
  const results = useStore((state) => state.results);
  const userInput = useStore((state) => state.userInput);

  const [showInsufficientCreditsAlert, setShowInsufficientCreditsAlert] = useState(true);

  const toggleYourWork = () => setShowYourWork(!showYourWork);

  const handleToggleDropdown = (nodeId = null) => {
    if (showDropdown && nodeId === null) {
      toggleDropdown(null);
    } else {
      toggleDropdown(nodeId);
    }
    setShowYourWork(false);
  };

  const startNewProject = () => {
    setCurrentProjectId(null);
    setCurrentProjectName('Untitled');
    localStorage.setItem('currentProjectName', 'Untitled');
    onNewProject();
  };

  const handleProjectNameChange = (newProjectName) => {
    setCurrentProjectName(newProjectName);
    localStorage.setItem('currentProjectName', newProjectName);
  };

  const handleProjectSaved = (newProject) => {
    setProjects([...projects, newProject]);
    handleProjectNameChange(newProject.name);
  };

  const updateProject = async () => {
    if (!user) {
      toast.error('User not logged in');
      return;
    }
    if (!currentProjectId) {
      toast.error('No project selected for update');
      return;
    }

    const projectData = { nodes, edges, results, userInput };
    setLoading(true);

    try {
      const { error } = await supabase
        .from('projects')
        .update({ data: projectData, public: false })
        .eq('id', currentProjectId);

      if (error) {
        console.error('Update Error:', error);
        throw error;
      }

      toast.success('Project updated successfully!');
    } catch (error) {
      toast.error('Error updating project');
      console.error('Error updating project:', error);
    } finally {
      setLoading(false);
    }
  };

  const signOut = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) {
      toast.error('Error signing out');
    } else {
      window.location.reload();
    }
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEditAccountOpen = () => {
    setOpenEditAccount(true);
    handleProfileMenuClose();
  };

  const handleUsageOpen = () => {
    setOpenUsage(true);
    handleProfileMenuClose();
  };

  const handleDialogClose = () => {
    setOpenEditAccount(false);
    setOpenUsage(false);
    setShowYourWork(false);
  };

  // Close dropdown when clicked outside
  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      (!yourWorkRef.current || !yourWorkRef.current.contains(event.target))
    ) {
      if (showDropdown) {
        toggleDropdown(null);
      }
      if (showYourWork) {
        setShowYourWork(false);
      }
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showDropdown, showYourWork]);

  useEffect(() => {
    if (user) {
      const fetchCredits = async () => {
        const { data, error } = await supabase
          .from('users')
          .select('credits')
          .eq('id', user.id)
          .single();

        if (error) {
          toast.error('Error fetching credits');
        } else {
          setCredits(data.credits);
        }
      };
      fetchCredits();
    }
  }, [user]);

  useEffect(() => {
    const savedProjectName = localStorage.getItem('currentProjectName');
    if (savedProjectName) {
      setCurrentProjectName(savedProjectName);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('currentProjectName', currentProjectName);
  }, [currentProjectName]);

  const handleProjectLoaded = useCallback(
    (projectId, projectName, nodes, edges, results, userInput) => {
      setCurrentProjectId(projectId);
      handleProjectNameChange(projectName);

      if (onProjectLoaded) {
        onProjectLoaded(nodes, edges, results, userInput);
      }
    },
    [onProjectLoaded]
  );

  const handleCloseAlert = () => {
    setShowInsufficientCreditsAlert(false);
  };

  // ==================== RENDER SUB-TABS ====================

  /**
   * Group "standaloneModules" by their "subcategory" field.
   */
  const renderModulesTab = () => {
    const groupedBySubcategory = modulesConfig.standaloneModules.reduce((acc, module) => {
      const { subcategory = 'Other' } = module.data;
      if (!acc[subcategory]) acc[subcategory] = [];
      acc[subcategory].push(module);
      return acc;
    }, {});

    return (
      <Box sx={{ padding: '10px' }}>
        {Object.keys(groupedBySubcategory).map((subcategory) => (
          <Box key={subcategory} sx={{ marginBottom: '24px' }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', marginBottom: '8px' }}>
              {subcategory}
            </Typography>
            <Box className="dropdown-grid">
              {groupedBySubcategory[subcategory].map((module) => (
                <Tooltip title={module.data.description} key={module.id}>
                  <Box className="dropdown-item" style={{ borderColor: module.data.color }}>
                    <DraggableItem
                      name={module.data.label}
                      icon={module.data.icon}
                      type="MODULE"
                      item={module}
                      color={module.data.color}
                      description={module.data.description}
                    />
                  </Box>
                </Tooltip>
              ))}
            </Box>
          </Box>
        ))}
      </Box>
    );
  };

  const renderScenariosTab = () => {
    return (
      <Box sx={{ padding: '10px' }}>
        <Typography variant="h6" className="menu-heading">
          Scenarios
        </Typography>
        <Box className="custom-scenarios-grid" sx={{ marginTop: '12px' }}>
          {Object.keys(modulesConfig.scenarios).map((scenarioKey) => (
            <Box
              key={scenarioKey}
              sx={{
                borderColor: modulesConfig.scenarios[scenarioKey].color,
                backgroundColor: '#fff',
                padding: '20px',
                borderRadius: '10px',
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                textAlign: 'center',
              }}
            >
              <DraggableItem
                name={modulesConfig.scenarios[scenarioKey].label}
                icon={modulesConfig.scenarios[scenarioKey].icon}
                type="SCENARIO"
                item={modulesConfig.scenarios[scenarioKey]}
                color={modulesConfig.scenarios[scenarioKey].color}
              />
            </Box>
          ))}
        </Box>
      </Box>
    );
  };

  const renderActionsTab = () => {
    return (
      <Box sx={{ padding: '10px' }}>
        <Typography variant="h6" className="menu-heading">
          Actions
        </Typography>
        <Box className="dropdown-grid" sx={{ marginTop: '12px' }}>
          {modulesConfig.actions.map((action) => (
            <Tooltip title={action.data.description} key={action.id}>
              <Box className="dropdown-item" style={{ borderColor: action.data.color }}>
                <DraggableItem
                  name={action.data.label}
                  icon={action.data.icon}
                  type="ACTION"
                  item={action}
                  color={action.data.color}
                  description={action.data.description}
                />
              </Box>
            </Tooltip>
          ))}
        </Box>
      </Box>
    );
  };

  const renderUserInputTab = () => {
    return (
      <Box sx={{ padding: '10px' }}>
        <Typography variant="h6" className="menu-heading">
          User Input
        </Typography>
        <Box className="dropdown-grid" sx={{ marginTop: '12px' }}>
          {modulesConfig.userInput.map((inputNode) => (
            <Tooltip title={inputNode.data.description} key={inputNode.id}>
              <Box className="dropdown-item" style={{ borderColor: inputNode.data.color }}>
                <DraggableItem
                  name={inputNode.data.label}
                  icon={inputNode.data.icon}
                  type="USER_INPUT"
                  item={inputNode}
                  color={inputNode.data.color}
                  description={inputNode.data.description}
                />
              </Box>
            </Tooltip>
          ))}
        </Box>
      </Box>
    );
  };

  return (
    <Box className="toolbar">
      {/* Alert if credits are 0 */}
      {credits === 0 && showInsufficientCreditsAlert && (
        <Alert
          severity="warning"
          style={{ position: 'fixed', top: 0, right: 0, zIndex: 9999 }}
          action={
            <IconButton aria-label="close" color="inherit" size="small" onClick={handleCloseAlert}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          <AlertTitle>Insufficient Credits</AlertTitle>
          You need to fund your account to continue working.
        </Alert>
      )}

      {/* If user is logged in, show buttons */}
      {user ? (
        <Box className="toolbar-buttons">
          <Tooltip title="Add a Node" placement="bottom">
            <IconButton
              onClick={() => handleToggleDropdown()}
              sx={{
                // Purple circle background
                backgroundColor: '#4f46e5',
                color: '#ffffff',
                borderRadius: '50%',
                width: '48px',
                height: '48px',
                '&:hover': { backgroundColor: '#7C3AED' },
              }}
            >
              {/* Icon bigger */}
              <AddCircleOutlineIcon sx={{ fontSize: 26 }} />
            </IconButton>
          </Tooltip>

          <Tooltip title="Your Work" placement="bottom">
            <IconButton
              onClick={toggleYourWork}
              sx={{
                backgroundColor: '#8B5CF6',
                color: '#ffffff',
                borderRadius: '50%',
                width: '48px',
                height: '48px',
                '&:hover': { backgroundColor: '#7C3AED' },
              }}
            >
              <FolderOpenIcon sx={{ fontSize: 26 }} />
            </IconButton>
          </Tooltip>

          <Tooltip title="Start New Project" placement="bottom">
            <IconButton
              onClick={startNewProject}
              sx={{
                backgroundColor: '#8B5CF6',
                color: '#ffffff',
                borderRadius: '50%',
                width: '48px',
                height: '48px',
                '&:hover': { backgroundColor: '#7C3AED' },
              }}
            >
              <NoteAddIcon sx={{ fontSize: 26 }} />
            </IconButton>
          </Tooltip>

          {currentProjectId && (
            <Tooltip title="Update Project" placement="bottom">
              <IconButton
                onClick={updateProject}
                className="update-project-button"
                disabled={loading || !currentProjectId}
                sx={{
                  backgroundColor: '#8B5CF6',
                  color: '#ffffff',
                  borderRadius: '50%',
                  width: '48px',
                  height: '48px',
                  '&:hover': { backgroundColor: '#7C3AED' },
                }}
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  <SaveIcon sx={{ fontSize: 26 }} />
                )}
              </IconButton>
            </Tooltip>
          )}
        </Box>
      ) : (
        <Button variant="contained" color="primary" href="/signup">
          Sign Up
        </Button>
      )}

      {/* Current project name in the middle */}
      <Box className="current-project" style={{ flexGrow: 1, textAlign: 'center' }}>
        <Typography
          variant="h6"
          className="current-project-name"
          onClick={toggleYourWork}
          style={{ cursor: 'pointer', display: 'inline-block', maxWidth: '300px' }}
        >
          {currentProjectName}
        </Typography>
      </Box>

      {/* Profile and credits */}
      {user && (
        <Box className="profile-and-credits">
          <Box className="toolbar-buttons">
            <Tooltip title="Credits" placement="left">
              <IconButton
                onClick={handleUsageOpen}
                sx={{
                  backgroundColor: '#8B5CF6',
                  color: '#ffffff',
                  borderRadius: '50%',
                  width: '48px',
                  height: '48px',
                  '&:hover': { backgroundColor: '#7C3AED' },
                }}
              >
                <MonetizationOnIcon sx={{ fontSize: 26 }} />
              </IconButton>
            </Tooltip>
          </Box>
          <Box className="profile-menu">
            <ProfileMenu
              user={user}
              anchorEl={anchorEl}
              openEditAccount={openEditAccount}
              openUsage={openUsage}
              handleProfileMenuOpen={handleProfileMenuOpen}
              handleProfileMenuClose={handleProfileMenuClose}
              handleEditAccountOpen={handleEditAccountOpen}
              handleUsageOpen={handleUsageOpen}
              handleDialogClose={handleDialogClose}
              signOut={signOut}
            />
          </Box>
        </Box>
      )}

      {/* Dropdown menu (with tabs) */}
      {showDropdown && user && (
        <Box className="dropdown-menu" ref={dropdownRef}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
            <Tabs
              value={dropdownTabIndex}
              onChange={(e, newValue) => setDropdownTabIndex(newValue)}
              aria-label="Node Tabs"
              TabIndicatorProps={{ style: { backgroundColor: '#8B5CF6' } }}
            >
              <Tab label="Modules" />
              <Tab label="Scenarios" />
              <Tab label="Actions" />
              <Tab label="User Input" />
            </Tabs>
            <IconButton onClick={() => toggleDropdown(null)} size="small">
              <CloseIcon />
            </IconButton>
          </Box>

          <Divider sx={{ mb: 2 }} />

          {/* Render content based on active tab */}
          {dropdownTabIndex === 0 && renderModulesTab()}
          {dropdownTabIndex === 1 && renderScenariosTab()}
          {dropdownTabIndex === 2 && renderActionsTab()}
          {dropdownTabIndex === 3 && renderUserInputTab()}
        </Box>
      )}

      {/* Dialog for "Your Work" (Save/Load) */}
      {user && (
        <Dialog open={showYourWork} onClose={handleDialogClose} maxWidth="md" fullWidth>
          <DialogTitle>Your Work</DialogTitle>
          <DialogContent dividers className="your-work-modal" ref={yourWorkRef}>
            <Tabs
              value={tabIndex}
              onChange={(e, newValue) => setTabIndex(newValue)}
              aria-label="Project Tabs"
              TabIndicatorProps={{ style: { backgroundColor: '#8B5CF6' } }}
            >
              <Tab label="Save Project" />
              <Tab label="Load Project" />
            </Tabs>

            {tabIndex === 0 && (
              <Box className="work-box">
                <SaveProject
                  user={user}
                  currentProjectId={currentProjectId}
                  setCurrentProjectId={setCurrentProjectId}
                  onProjectSaved={handleProjectSaved}
                  handleProjectNameChange={handleProjectNameChange}
                />
              </Box>
            )}
            {tabIndex === 1 && (
              <Box className="work-box">
                <LoadProject
                  user={user}
                  setCurrentProjectId={setCurrentProjectId}
                  setCurrentProjectName={handleProjectNameChange}
                  projects={projects}
                  setProjects={setProjects}
                  onProjectLoaded={handleProjectLoaded}
                />
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
};

export default Toolbar;
